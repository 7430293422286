// ==================== //
//     Global Imports   //
// ==================== //

// @import "~react-toastify/dist/ReactToastify.css";

@font-face {
  font-family: "Poppins Light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Poppins-Light.ttf") format("truetype");
}

// @font-face {
//   font-family: "Open Sans Regular";
//   font-style: normal;
//   font-weight: normal;
//   src: url("../fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
// }