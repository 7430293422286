
main {
  min-height: 44.9vh;
}

.under-construction-img {
  margin-top: 6rem;  
  display: block;
  margin-left: auto;
  margin-right: auto; 
  
}

@media screen and (max-width: 960px) {
  .under-construction-img {
     width: 100%;
  }
  main {
    min-height: 30.5vh;
  }
}
