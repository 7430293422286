

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;

  justify-content: center;
  align-items: center;
  border: 1px solid black ;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins Light", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  background-color: $bg-body-color;

  font-size: $font-size-base;
  // font-weight: 800;
}

main {
  margin: auto;
  align-items: center;
}

::-moz-focus-inner {
  border: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}